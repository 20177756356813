let mobx = require('mobx');
// create State object
let data = {
    timer: 0,
    names: ['', ''],
    user_name: '',
    user_password: '',
    email: '',
    phone_num: '',
    email_code: '',
    phone_code: '',
    email_code_time: 0,//短信验证码有效时间
}
let loginState = mobx.observable(data);

// export default loginState;
module.exports = loginState;
let mobx = require('mobx');
// create State object
let data = {
    order: {},
    rate: null,//评价分数
    evaluate_desc: '',//评价描述
    rate_effective: null,
    rate_efficiency: null,
    rate_voice_accent: null,
    rate_professionality: null,
    rate_vocabulary: null,
    is_show_detail: false,
}
let orderEvaluate = mobx.observable(data);

// export default loginState;
module.exports = orderEvaluate;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '../../common/css/commonLess.less';
import 'ant-design-pro/dist/ant-design-pro.css'; // 统一引入样式

import App from './App';
import reportWebVitals from './reportWebVitals';
require('commonProject/config/mobx_config');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

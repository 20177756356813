let mobx = require('mobx');
// create State object
let data = {
    chooseLangs: [],
    chooseCerts: [],
    chooseSupportService: [],
    prebooking: [{type: 'prebooking'}, {type: 'now'}],
    prebooking_time: {
        hour: 0,
        minute: 0,
    },
    usable_times: [
        //开始单元78  一单元为5分钟，开始时间为3:00， 78个单元后就是下面的startTime。   num表示单元长度10，就是50分钟
        // {startUnit: 78, num: 10, startTime: "2021-03-19T01:30:00.000Z", endTime: "2021-03-19T02:20:00.000Z"},
    ],
    book_confirm: {
        is_show_ok_dialog: false,
    },
    request_times: 1,//请求获取可用时间的次数
}
let loginState = mobx.observable(data);

// export default loginState;
module.exports = loginState;
let mobx = require('mobx');
// create State object
let data = {
    order: {},
    recordList: [],
    inputTxt: '',
}
let cancelOrderState = mobx.observable(data);

// export default loginState;
module.exports = cancelOrderState;
let mobx = require('mobx');
// create State object
let data = {
    orders: [],//订单列表
    selectItemOrder: {},//已选择的订单子项
    loading: false,
    hasMore: true,
    page: 1,
    pageSize: 10,
    is_show_sub_user_setting_dialog: false,//是否显示子用户控制对话框
    subUsers: [],//子用户数据
    account: '',//用户电话号或者邮箱号
}
let homeLoginState = mobx.observable(data);

// export default loginState;
module.exports = homeLoginState;
let mobx = require('mobx');
// create State object
let data = {
    order: {},
    change_book_time: {//change_book_time页面数据
        order: {},
        request_times: 1,//请求获取可用时间的次数
        usable_times: [],
        prebooking_time: {
            hour: 0,
            minute: 0,
        },
    },
}
let editOrderState = mobx.observable(data);

// export default loginState;
module.exports = editOrderState;
let mobx = require('mobx');
// create State object
let data = {
    order: {},
    contacts: [],
    choose_contact: {},
    phone_num: '',
    contact_nick_name: '',
}
let cancelOrderState = mobx.observable(data);

// export default loginState;
module.exports = cancelOrderState;